<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS INFORMES A LA COMUNIDAD</span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col md="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-file-contract fa-3x"></i>
                            <br>
                            <span class="lg-numero">{{listaInformes.length}}</span>
                            <br>
                            <span class="text-muted">Informes registrados</span>
                        </b-col>
                    </b-row>

                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de informes a la comunidad</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('016-VCM-INC','c')==1" md="6" class="my-2">
                            <b-button block size="md" @click="modalRegistrarInforme = true" variant="custom">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo informe
                            </b-button>
                        </b-col>
                        <b-col v-if="checkPermissions('016-VCM-INC-COM','r')==1" md="6" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'Comunidad'}">
                                <i style="inline-size: auto" class="fas fa-people-carry fa-3x pb-1"></i><br>Agregar comunidad
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Categorías registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaInformes" :fields="campoCategorias" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="param.item.urlDocumento != ''" @click="descargarDocumento(param.item.urlDocumento)" class="mr-1 mb-1" size="sm" variant="blue" v-c-tooltip="'Descargar'">
                                        <i class="fas fa-download  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('016-VCM-INC','u')==1" @click="abrirModalDetalleInformes(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('016-VCM-INC','d')==1" @click="eliminarInforme(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" color="primario" size="lg" :show.sync="modalRegistrarInforme">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo informe </span>
            </h6>
            <CButtonClose @click="modalRegistrarInforme = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarInforme)">
                <b-row>
                    <b-col md=12>
                        <validation-provider name="comunidad" rules="required" v-slot="{errors}">
                            <b-form-group label="Comunidad:" class="mb-2">
                                <v-select :reduce="listaComunidades =>listaComunidades.idComunidad" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosInformes.idComunidad , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosInformes.idComunidad" :options="listaComunidades" @search:blur="blurComunidad">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Comunidad'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosInformes.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="documento" v-slot="validationContext">
                            <b-form-group label="Documento:" class="mb-2">
                                <b-form-file ref="file" v-model="datosInformes.documento" v-on:change="handleFileUpload" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarInforme = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalActualizarInforme">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar categoría</span></h6>
            <CButtonClose @click="modalActualizarInforme = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarCategoria)">
                <b-row>
                    <b-col md=12>
                        <validation-provider name="comunidad" rules="required" v-slot="{errors}">
                            <b-form-group label="Comunidad:" class="mb-2">
                                <v-select :reduce="listaComunidades =>listaComunidades.idComunidad" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarInforme.idComunidad , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarInforme.idComunidad" :options="listaComunidades" @search:blur="blurActualizarComunidad">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Comunidad'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="descripción" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarInforme.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="documento" v-slot="validationContext">
                            <b-form-group label="Documento:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosActualizarInforme.urlDocumento != ''">
                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosActualizarInforme.urlDocumento)" v-c-tooltip="'Descargar'">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file ref="fileActualizar" v-model="datosActualizarInforme.documento" :state="(getValidationState(validationContext) )" :placeholder="datosActualizarInforme.nombreDocumento ?  datosActualizarInforme.nombreDocumento : 'Elija un archivo o arrástrelo aquí...'" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUploadActualizar"></b-form-file>
                                </b-input-group>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>

                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarInforme = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            disabled: false,
            modalRegistrarInforme: false,
            modalActualizarInforme: false,
            totalRows: 1,
            currentPage: 1,
            porPagina: 10,
            filter: null,
            filterOn: [],
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoCategorias: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "comunidad",
                    label: "Comunidad",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],

            listaInformes: [],
            listaComunidades: [],

            datosInformes: {
                idCliente: '',
                idComunidad: null,
                descripcion: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
                documento: null,
                urlDocumento: '',
                nombreDocumento: '',
            },
            datosActualizarInforme: {
                idInformeComunidad: null,
                idCliente: '',
                idComunidad: null,
                descripcion: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
                documento: null,
                urlDocumento: '',
                nombreDocumento: '',
            },
             accept: [
                '.vsd', '.vsdx', '.xlsx', '.xls', '.csv',
                '.pdf', '.doc', '.docx', '.ppt', '.pptx',
                '.png', '.jpg', '.jpeg', '.pdf', '.docx',
                'application/msword',
                'application/vnd.ms-excel'
            ]
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                this.datosInformes.documento = this.$refs.file.files[0];
            }
        },
        handleFileUploadActualizar(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileActualizar"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileActualizar"].reset();
                    return;
                }
                this.datosInformes.documento = this.$refs.fileActualizar.files[0];
            }
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        abrirModalDetalleInformes(param) {
            let me = this;

            me.datosActualizarInforme.idInformeComunidad = param.item.idInformeComunidad;
            me.datosActualizarInforme.idComunidad = param.item.idComunidad;
            me.datosActualizarInforme.descripcion = param.item.descripcion;
            me.datosActualizarInforme.urlDocumento = param.item.urlDocumento;
            me.datosActualizarInforme.nombreDocumento = param.item.nombreDocumento;
            me.modalActualizarInforme = true;
        },
        listarInformes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-informes-comunidad", {
                        params: {
                            idCliente: me.datosInformes.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs
                        },
                    }
                )
                .then(function (response) {
                    me.listaInformes = response.data;
                    me.totalRows = me.listaInformes.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarInforme() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file", me.datosInformes.documento);

            formData.append("folder", 'empresa-segura');
            /*---Datos del objeto--*/
            formData.append("idCliente", me.datosInformes.idCliente);
            formData.append("idComunidad", me.datosInformes.idComunidad);
            formData.append("descripcion", me.datosInformes.descripcion);
            formData.append("ejercicio", me.datosInformes.ejercicio);

            formData.append("urlDocumento", me.datosInformes.urlDocumento);
            formData.append("nombreDocumento", me.datosInformes.nombreDocumento);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-informe-comunidad",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalRegistrarInforme = false;
                    me.listarInformes();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },
        descargarDocumento(url) {
            window.open(url)
        },
        actualizarCategoria() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file", me.datosActualizarInforme.documento);

            formData.append("folder", 'empresa-segura');
            /*---Datos del objeto--*/
            formData.append("idCliente", me.datosActualizarInforme.idCliente);
            formData.append("idInformeComunidad", me.datosActualizarInforme.idInformeComunidad);

            formData.append("idComunidad", me.datosActualizarInforme.idComunidad);
            formData.append("descripcion", me.datosActualizarInforme.descripcion);
            formData.append("ejercicio", me.datosActualizarInforme.ejercicio);

            formData.append("urlDocumento", me.datosActualizarInforme.urlDocumento);
            formData.append("nombreDocumento", me.datosActualizarInforme.nombreDocumento);

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-informe-comunidad",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarInforme = false;
                    me.listarInformes();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },
        resetModalRegistrarInforme() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosInformes.nombre = '';
            this.datosInformes.descripcion = '';
            this.datosInformes.documento = null;
            this.datosInformes.idComunidad = null;
            this.datosInformes.urlDocumento = '';
            this.datosInformes.nombreDocumento = '';

        },
        resetModalActualizarInforme() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosActualizarInforme.documento = null;

        },

        eliminarInforme(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el informe?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-informe-comunidad", {
                                idInformeComunidad: param.item.idInformeComunidad
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarInformes();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        blurComunidad() {
            this.computedForm.refs.comunidad.validate();
        },
        blurActualizarComunidad() {
            this.computedActualizarForm.refs.comunidad.validate();
        },

        listarComunidades() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-comunidades", {
                        params: {
                            idCliente: me.datosInformes.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs
                        },
                    }
                )
                .then(function (response) {
                    me.listaComunidades = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    watch: {
        modalRegistrarInforme: function (val) {
            if (val == false) {
                this.resetModalRegistrarInforme();
            }
        },
        modalActualizarInforme: function (val) {
            if (val == false) {
                this.resetModalActualizarInforme();
            }
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosInformes.idCliente = this.datosActualizarInforme.idCliente = user.uidClient;
            this.listarInformes();
            this.listarComunidades();

        }
    }

}
</script>
